<template>
    <a type="button" class="button-search" @click="toggleSearchMenu" v-bind:class="{ 's-active': search }">
        <span class="button-search__text">{{ $t('siteSearch.title') }}</span>
        <SvgIcon :icon="'search/outline'" />
    </a>
    <SearchWidget v-model="search" />
</template>

<script lang="ts" setup>
import SvgIcon from '@/components/base/SvgIcon.vue';
import { ref } from 'vue';
import SearchWidget from './components/SearchWidget.vue';

const search = ref(false);
const toggleSearchMenu = () => {
    search.value = !search.value;
};
</script>

<style lang="scss" scoped>
@import '~/styles/transitions.scss';

.button-search {
    color: $c-white;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        margin-right: $space-xs;
    }

    :deep() .icon {
        width: 14px;
    }

    &:hover {
        cursor: pointer;
    }
}
</style>
