<template>
    <div>
        <BaseLightbox v-model="show" :maxWidth="640" :title="$t('siteSearch.title')" :subtitle="$t('siteSearch.subtitle')">
            <div class="search-container">
                <SearchInput class="search-input" :redirectToPage="true" :showDropdown="true" />
            </div>
        </BaseLightbox>
    </div>
</template>

<script lang="ts" setup>
import BaseLightbox from "@/components/base/BaseLightbox.vue";
import SearchInput from "@/components/site-search/SearchInput.vue";
import { computed } from "vue";

const props = defineProps<{
    modelValue: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const show = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});
</script>

<style lang="scss" scoped>
.search-container {
    :deep() .search {
        width: 100%;
    }

    :deep() svg.icon {
        width: 24px;
        height: 24px;
    }

    header {
        margin-bottom: 20px;
    }

    .search-input {
        min-width: 100%;
    }
}
</style>
